import React, { useState } from 'react';
import { useSearch } from '@/contexts/products/SearchContext';
import { ModalInterface } from '@/types';
import HandleModal from '@/components/modals/BlurModal';
import EpjLoadingIcon from '@/components/animations/EpjLoadingIcon';
import { useProducts } from '@/contexts/products/ProductsContext';
import ProductsSelectedModal from '../ProductsSelectedModal';
import SearchField from './SearchField';
import { Campaign } from '@/types/campaigns';
import DropdownPackOptions from "@/components/buttons/DropDownPackOptions";

interface ISearchBar {
	campaigns: Campaign[];
	showPackFilter?: boolean;
}

const SearchBar: React.FC<ISearchBar> = ({ campaigns, showPackFilter }) => {
	const {
		searchType,
		searchResults,
		isSearching,
		currentPage,
		setCurrentPage,
	} = useSearch();

	const { isTableEmpty } = useProducts();

	const handleNextPage = () => {
		setCurrentPage(currentPage + 1);
	};

	const [showProductsSelected, setShowProductsSelected] = useState(false);


	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	return (
		<div className="flex flex-col items-center justify-center mt-5 w-full mb-4">
			<LoadingModal isOpen={isSearching} onClose={() => null} />
			<div className="relative flex items-center justify-center mb-2 w-full">
				<div className="relative w-3/5 mr-2">
					<SearchField campaigns={campaigns} />
					
				</div>

				{showPackFilter && <DropdownPackOptions /> }

				<div className="flex items-center justify-between bg-white">
					<div className="flex flex-1 justify-between">
						<a href="#" onClick={handlePreviousPage} className={`
							relative shadow inline-flex items-center rounded-full px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 ${currentPage === 1 ? 'cursor-not-allowed pointer-events-none opacity-30' : ''}`}>
							<svg className="h-5 w-5 text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
							</svg>
						</a>
						<a href="#" onClick={handleNextPage} className={
							`relative ml-3 shadow inline-flex items-center rounded-full px-2 py-2 text-sm font-medium text-gray-600 hover:bg-gray-100 ${isTableEmpty || searchResults.length < 20 ? 'cursor-not-allowed pointer-events-none opacity-30' : ''}`}>
							<svg className="h-5 w-5 text-orange-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
								<path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
							</svg>
						</a>
					</div>
				</div>

				<ProductsSelectedModal isOpen={showProductsSelected} onClose={() => setShowProductsSelected(false)} />
			</div>
		</div>
	);
};

export default SearchBar;

const LoadingModal: React.FC<ModalInterface> = (props) => {
	return (
		<HandleModal isOpen={props.isOpen} onClose={props.onClose}
			bgClass={"none"}
			className={"shadow-none"} dontWantPadding={true}>
			<EpjLoadingIcon />
		</HandleModal>
	)
}

