import React from 'react';
import { Menu } from '@headlessui/react';
import { ArrowDown } from '@/components/svg/Arrows';
import TextHelper from '@/helpers/TextHelper';
import { Campaign } from '@/types/campaigns';
import { ITemplate, useSearch } from '@/contexts/products/SearchContext';
import Api from '@/api/Api';
import { useMember } from '@/contexts/member/MemberContext';
import Notify from '@/helpers/Notify';
import { PriceType } from '@/types/canvas';
import { useBatch } from '@/contexts/batch/BatchProductsContext';

interface DropdownButtonInterface {
    campaigns: Campaign[],
    disable?: boolean,
    className?: string,
    isBatch?: boolean,
}

const DropdownCampaignsOptions: React.FC<DropdownButtonInterface> = ({ disable, campaigns, className, isBatch = false }) => {
    const { selectedCampaign, setSelectedCampaign, setTemplatesFromSelectedCampaign } = useSearch();
    const { setUniquePriceTypes } = useBatch();
    const { member } = useMember();

    const onClickOption = (e: React.MouseEvent, campaign: Campaign) => {
        e.stopPropagation();
        setSelectedCampaign(campaign);
        fetchTemplatesForSelectedCampaignId(campaign);
    }

    const fetchTemplatesForSelectedCampaignId = async (campaign: Campaign) => {
        const api = new Api('campaigns');
        const request = api.request(member, { campaignId: campaign.id });

        const { success, data } = await api.post(request, 'lt');

        if (!success) return Notify.Warn("Erro ao buscar os cartazes");

        let templates: ITemplate[] = data.templates;
        if (isBatch) templates = data.templates.filter((template: { ComboType: null; }) => template.ComboType === null);

        if (templates.length === 0) Notify.Warn("Essa campanha não possui nenhum cartaz!");

        setTemplatesFromSelectedCampaign(templates);

        // filtra pelos modelos disponivel para não quebrar na hora de Gerar
        if (templates.length > 0 && isBatch) {
            const PriceTypes = Array.from(
                new Set(
                    templates.map((template) => {
                        return template.PriceType as PriceType;
                    }).filter((priceType): priceType is PriceType => priceType !== null)
                )
            );

            setUniquePriceTypes(PriceTypes);
        }
    }

    const isNotSelectedCampaign = selectedCampaign === undefined;
    const sortedCampaigns = campaigns.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });

    return (
        <Menu as="div" className={`
                ${className ? className : "xl:w-56 lg:w-40 md:w-32 h-full"} relative text-left
            `}>
            <Menu.Button
                disabled={disable}
                className={`${disable && "w-5 cursor-not-allowed "}
                    ${className ? className : "xl:w-56 lg:w-40 md:w-32 h-full"}
                    flex items-center justify-between border border-gray-300 font-semibold
                    rounded-lg px-4 py-2 text-zinc-500 focus:outline-none bg-white
                    ${isNotSelectedCampaign && "animate-pulse"}`}
            >
                <span className='flex w-full overflow-hidden text-ellipsis whitespace-nowrap'>
                    {selectedCampaign ? selectedCampaign.name : "Selecione uma campanha"}
                </span>

                <ArrowDown className='ml-2 m-auto stroke-zinc-500 fill-zinc-500' />
            </Menu.Button>

            <Menu.Items className="origin-top-left absolute right-0 mt-1 w-full rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-transform duration-200 transform scale-95 ease-in-out z-10">
                <div className="max-h-[30vh] rounded-lg overflow-auto divide-y" onClick={e => e.stopPropagation()}>
                    {
                        sortedCampaigns.map((item, index) => (
                            <Menu.Item key={index}>
                                {({ active }) => (
                                    <div
                                        onClick={(e) => onClickOption(e, item)}
                                        className={`${active ? 'bg-blue-500 text-white' : 'text-gray-700'
                                            } flex justify-between w-full px-4 py-2 cursor-pointer`}
                                    >
                                        <span>{TextHelper.charLimit(item.name, 40)}</span>
                                    </div>
                                )}
                            </Menu.Item>
                        ))
                    }
                </div>
            </Menu.Items>
        </Menu>
    )
}

export default DropdownCampaignsOptions;