import { ReactChildren } from "@/types";
import { ProductListItem, ProductsQuantity, SilverProduct } from "@/types/products";
import { createContext, Dispatch, useContext, useEffect, useReducer, useState } from "react";
import { useMember } from "../member/MemberContext";
import { PriceType } from "@/types/canvas";
import { useQueue } from "../queue/QueueContext";
import { IPaperQuantity, IPaperSize } from "@/components/modals/products/search/IProductSelectionModal";
import Api from "@/api/Api";
import { extractSilverProducts } from "../products/SearchContext";
import { IProductParams } from "@/components/drawers/components/CardProductParams";
import { useCompany } from "../company/CompanyContext";
import { EntityID } from "@/types/silverstripe";
import BatchProductsReducer from "./reducer/BatchProductReducer";
import { BatchProduct, IBatchProductReducerAction } from "./reducer/interface/IBatchProductReducer";
import { usePricing } from "../pricing/PricingContext";

type IBatch = {
	cacheProductsModal: ProductListItem[],
	setCacheProductsModal: (products: ProductListItem[]) => void,

	productsQuantity: ProductsQuantity[],
	setProductsQuantity: (product: ProductsQuantity[]) => void,

	selectedProducts: BatchProduct[],
	setSelectedProducts: (product: BatchProduct[]) => void,

	selectedMarkets: IMarketField[],
	setSelectedMarkets: (markets: IMarketField[]) => void,

	listBatch: Item[] | null,
	setListBatch: (listBatch: Item[]) => void,

	listAvailableDates: Item[],
	setListAvailableDates: (date: Item[]) => void,

	batchSelected: Item | null,
	setBatchSelected: (batch: Item | null) => void,

	uniquePriceTypes: PriceType[],
	setUniquePriceTypes: (uniquePriceTypes: PriceType[]) => void,

	selectedDate: Item | null,
	setSelectedDate: (selectedDateOffer: Item | null) => void,

	paperOrientation: IPaperSize,

	templateOrientation: IPaperSize,
	setTemplateOrientation: (orientation: IPaperSize) => void,

	resetQuantityInputs: () => void,
	resetQuantityState: () => void,
	getBatchList: () => Promise<void>,

	getBatchProducts: (batchId: number, modalManager?: boolean) => Promise<ProductListItem[]>,

	isLoading: boolean,
	setIsLoading: (value: boolean) => void;

	isReuseQtd: boolean,
	setReuseQtd: (value: boolean) => void;

	isBatchManagerModal: boolean,
	setIsBatchManagerModal: (value: boolean) => void;

	adminMode: AdminMode,
	setAdminMode: (mode: AdminMode) => void;

	adminMarket: MarketsAdmin | null,
	setAdminMarket: (value: any) => void;

	marketsCompany: MarketsAdmin[],

	hasRole: boolean | null,

	batchScenario: string | null,

	today: string,
	tomorrow: string,

	batchProducts: BatchProduct[],
	batchProductsDispatch: Dispatch<IBatchProductReducerAction>

	initQuantityProducts: (batchProducts: any) => void,
}

type Item = {
	label: string,
	data?: any
}

type AdminMode = "manage" | "print" | "";

type MarketsAdmin = { id: EntityID, name: string, storeID: number }

export type IMarketField = { label: string, id: number }
const BatchProducts = createContext<IBatch>({} as IBatch);

export const BathProductsProvider = ({ children }: { children: ReactChildren }) => {
	const { member } = useMember();
	const { company } = useCompany();
	const { allowedOrientations } = useQueue();
	const { getPriceTypeAllowedList } = usePricing();

	const today = new Date().toISOString().slice(0, 10);
	const tomorrow = new Date(Date.now() + 86400000).toISOString().slice(0, 10);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isReuseQtd, setReuseQtd] = useState<boolean>(false);
	const [isBatchManagerModal, setIsBatchManagerModal] = useState<boolean>(false);

	// State of Products
	const [batchProducts, batchProductsDispatch] = useReducer(BatchProductsReducer, []);

	const [selectedProducts, setSelectedProducts] = useState<BatchProduct[]>([]);
	const [productsQuantity, setProductsQuantity] = useState<ProductsQuantity[]>([]);

	// State of Filters
	const [listBatch, setListBatch] = useState<Item[]>([]);
	const [batchSelected, setBatchSelected] = useState<Item | null>(null);

	const [listAvailableDates, setListAvailableDates] = useState<Item[]>([]);
	const [selectedDate, setSelectedDate] = useState<Item | null>(null);

	// State of create Batch
	const [selectedMarkets, setSelectedMarkets] = useState<IMarketField[]>([]);
	const [uniquePriceTypes, setUniquePriceTypes] = useState<PriceType[]>([]);

	// state of batch Modal
	const [cacheProductsModal, setCacheProductsModal] = useState<ProductListItem[]>([]);

	const paperOrientation = Object.entries(allowedOrientations)
		.find(([_, value]) => Boolean(value) === true)?.[0] as IPaperSize;

	// Template orientation
	const [templateOrientation, setTemplateOrientation] = useState<IPaperSize>(paperOrientation);

	// Admin Mode Options
	const [adminMode, setAdminMode] = useState<AdminMode>("");

	// Markets Admin Control
	const [adminMarket, setAdminMarket] = useState<MarketsAdmin | null>(null);

	const marketsCompany: MarketsAdmin[] = !company ? []
		: company.markets.map((market) => ({ id: market.id, name: market.name, storeID: market.storeID }));

	useEffect(() => {
		setAdminMode(hasRole ? 'manage' : 'print');

		const userMarket = marketsCompany.find(market => market.storeID === member.marketID);
		userMarket && setAdminMarket(userMarket);
	}, [member.marketID, company]);

	const resetQuantityInputs = () => {
		const inputs = document.querySelectorAll('input.qtd');
		inputs.forEach(input => {
			const inputElement = input as HTMLInputElement;
			inputElement.value = '0';
		});
	}

	const resetQuantityState = () => {
		const resetedData = productsQuantity.map(qtd => zeroBulkQtd(qtd.ean))
		setProductsQuantity(resetedData);
	}

	const hasRole = member.role && ["manager", "marketing", "admin"].includes(member.role);

	const batchScenario = company?.batchScenario || null;

	function zeroBulkQtd(ean: EntityID): ProductsQuantity {
		return { ean, _2A3: 0, A3: 0, _2A4: 0, A4: 0, A5: 0, A6: 0, A7: 0, p30: 0, p21: 0 };
	}

	const getBatchProducts = async (batchId: number, modalManager: boolean = false) => {
		const api = new Api("bulk", "gl");

		const request = api.request(member, { batchId, storeID: member.marketID });
		const { success, code, message, data } = await api.post(request);

		if (!success && code === "404") {
			console.error(message);
			return [];
		}

		const parseToProductListItem: ProductListItem[] = data.map((prod: SilverProduct) => extractSilverProducts(prod));

		batchProductsDispatch({ type: "addProductList", products: parseToProductListItem });

		const batchProductQuantity = parseToProductListItem.map(prod => zeroBulkQtd(prod.ean));

		setProductsQuantity(batchProductQuantity);

		localStorage.setItem("selectedBatchProducts", JSON.stringify(parseToProductListItem));		

		return parseToProductListItem;
	}

	const initQuantityProducts = (batchProducts: any) => {
		batchProductsDispatch({ type: "addProductList", products: batchProducts });
		const batchProductQuantity = batchProducts.map((prod:any) => zeroBulkQtd(prod.ean));
		setProductsQuantity(batchProductQuantity);
	}

	const getBatchList = async () => {
		const api = new Api("batch", "gbc");
		const request = api.request(member, { companyID: member.companyID });
		const { success, data } = await api.post(request);

		if (!success) return console.log("Não encontramos nenhum lote!");

		const dropdownData = data.map((batch: {
			ID: number,
			Name: string,
			CampaignID: string
		}) => ({ label: batch.Name, data: batch.ID, CampaignID: batch.CampaignID }));

		setListBatch(dropdownData);
	}

	//set default
	useEffect(() => { setTemplateOrientation(paperOrientation); }, [paperOrientation]);
	useEffect(() => { resetQuantityState(), resetQuantityInputs() }, [isReuseQtd]);

	return (
		<BatchProducts.Provider value={{
			cacheProductsModal,
			setCacheProductsModal,

			productsQuantity,
			setProductsQuantity,

			selectedProducts,
			setSelectedProducts,

			listBatch,
			setListBatch,

			selectedMarkets,
			setSelectedMarkets,

			listAvailableDates,
			setListAvailableDates,

			batchSelected,
			setBatchSelected,

			uniquePriceTypes,
			setUniquePriceTypes,

			selectedDate,
			setSelectedDate,

			paperOrientation,

			templateOrientation,
			setTemplateOrientation,

			resetQuantityInputs,
			resetQuantityState,
			getBatchList,

			getBatchProducts,

			isLoading,
			setIsLoading,

			isReuseQtd,
			setReuseQtd,

			isBatchManagerModal, 
			setIsBatchManagerModal,

			adminMode,
			setAdminMode,

			adminMarket,
			setAdminMarket,

			hasRole,
			marketsCompany,

			batchScenario,

			today,
			tomorrow,

			batchProducts,
			batchProductsDispatch,

			initQuantityProducts
		}}>
			{children}
		</BatchProducts.Provider>
	);
};

export const useBatch = (): IBatch => {
	const context = useContext(BatchProducts);

	if (!context) {
		throw new Error(
			"useBatch must be used within an BatchProductProvider"
		);
	};

	return context;
};
