import React, { useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { ArrowDown } from '@/components/svg/Arrows';
import TextHelper from '@/helpers/TextHelper';
import { useSearch, PackOption } from '@/contexts/products/SearchContext';
import { useCompany } from '@/contexts/company/CompanyContext';

interface DropdownButtonInterface {
    disable?: boolean,
    className?: string,
}

const DropdownPackOptions: React.FC<DropdownButtonInterface> = ({ disable = false, className }) => {
    const { selectedPack, setSelectedPack } = useSearch();
    
    const { packTypeList } = useCompany();

    useEffect(() => {
        if (packTypeList.length > 0 && !selectedPack) {
            setSelectedPack(packTypeList[0]); // Seleciona o primeiro item da lista se selectedPack estiver vazio
        }
    }, [packTypeList, selectedPack]);

    const onClickOption = (e: React.MouseEvent, pack: PackOption) => {
        e.stopPropagation();
        setSelectedPack(pack);
    }

    return (
        <Menu as="div" className={`
                ${className ? className : "xl:w-56 lg:w-40 md:w-32 h-full"} relative text-left
            `}>
            <Menu.Button
                disabled={disable}
                className={`${disable && "w-5 cursor-not-allowed "}
                    ${className ? className : "xl:w-56 lg:w-40 md:w-32 h-full"}
                    flex items-center justify-between border border-gray-300 font-semibold
                    rounded-lg px-4 py-2 text-zinc-500 focus:outline-none bg-white
                    `}
            >
                <span className='flex w-full overflow-hidden text-ellipsis whitespace-nowrap'>
                    {selectedPack ? selectedPack.PackType : "Selecione uma campanha"}
                </span>

                <ArrowDown className='ml-2 m-auto stroke-zinc-500 fill-zinc-500' />
            </Menu.Button>

            <Menu.Items className="origin-top-left absolute right-0 mt-1 w-full rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition-transform duration-200 transform scale-95 ease-in-out z-10">
                <div className="max-h-[30vh] rounded-lg overflow-auto divide-y" onClick={e => e.stopPropagation()}>
                    {
                        packTypeList.map((item , index) => (
                            <Menu.Item key={index}>
                                {({ active }) => (
                                    <div
                                        onClick={(e) => onClickOption(e, item)}
                                        className={`${active ? 'bg-blue-500 text-white' : 'text-gray-700'
                                            } flex justify-between w-full px-4 py-2 cursor-pointer`}
                                    >
                                        <span>{TextHelper.charLimit(item.PackType, 40)}</span>
                                    </div>
                                )}
                            </Menu.Item>
                        ))
                    }
                </div>
            </Menu.Items>
        </Menu>
    )
}

export default DropdownPackOptions;