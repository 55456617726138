import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import { uuid } from "@/helpers";
import Notify from "@/helpers/Notify";
import DeleteProductPopover from '@/components/popover/DeleteProductPopover';
import { useEffect, useRef, useState } from "react";
import React from "react";
import { ArrowDown, ArrowUp } from "@/components/svg/Arrows";
import useBoolean from "@/hooks/useBoolean";
import Select, { StylesConfig } from 'react-select';
import { useCanvasProducts } from "@/contexts/editor/CanvasProductsContext";
import { EntityID } from "@/types/silverstripe";
import { BannerProducts } from "@/types/products";
import { useModals } from "@/contexts/modals/ModalsContext";
import ComponentProvider from "@/components/editor/ComponentProvider";
import TextHelper from "@/helpers/TextHelper";
import DescriptionIcon from "@/components/svg/DescriptionIcon";
import MoneyIcon from "@/components/svg/MoneyIcon";
import WeightIcon from "@/components/svg/WeightIcon";
import FileImageIcon from "@/components/svg/FileImageIcon";
import BarCodeIcon from "@/components/svg/BarCodeIcon";
import DigitalIcon from "@/components/svg/DigitalIcon";
import DateClockIcon from "@/components/svg/DateClockIcon";
import PackageIcon from "@/components/svg/packageIcon";
import JsBarcode from "jsbarcode";

interface ProductRowProps {
	id: string;
	pos: number;
}

interface ProductPanelInterface {
	productID: string
}

type SelectOption = { value: EntityID, label: string };
const ProductRow: React.FC<ProductRowProps> = ({ id, pos }) => {
	const {
		isEditing, state, updateProductTextElements,
		getElementsByProductId, updateProductImageElements
	} = useCanvas();

	const { stateDispatch } = useCanvasDispatch();

	const { elements, editor } = state;

	const { setSearchProductModal } = useModals();

	const showSearchModal = () => setSearchProductModal(true);

	const { indexedProducts, setIndexedProducts, removeProductFromIndex, products: addedProducts, bannerProducts, setBannerProducts } = useCanvasProducts();
	const selectProducts: Array<SelectOption> = addedProducts.map(addedProduct => ({ value: addedProduct.id, label: addedProduct.description }));
	// Encontre o produto associado a este ProductRow, se houver
	const selectedProductID = indexedProducts[id];

	const groupId = useRef<string | null>(null);
	const hoverTimeoutId = useRef<number | null>(null);

	const [isGrouped, setIsGrouped] = useState(false);
	const { value: isRowOpened, toggle: toggleRowOpened } = useBoolean(false);

	const centerClass = "flex justify-start items-center";

	const displayPosition = String(pos).padStart(2, '0');

	useEffect(() => {
		if (pos === 1) {
			if (!selectedProductID && addedProducts.length > 0) {
				const first = addedProducts[0];
				updateProductElements(first.id);
			}
			else if (addedProducts.length === 0) setBannerProducts([]);
		}
	}, []);

	useEffect(() => {
		if (!groupId.current) {
			let isAlreadyGrouped = false;
			let foundGroupId: string | null = null;
			elements.every(element => {
				const isText = !!element.attributes.textType;
				const elementGroupId = element.attributes.groupID;
				const elementProductId = element.attributes.productID;
				// Verifica se um elemento de texto associado a este produto
				// possui um grupo associado a ele.
				isAlreadyGrouped = isText && !!elementGroupId && elementProductId == id;
				if (isAlreadyGrouped) {
					foundGroupId = elementGroupId;
					return false; // interrompe a iteração.
				}
				return true; // continua a iterar.
			});

			if (isAlreadyGrouped && foundGroupId) {
				groupId.current = foundGroupId;
				setIsGrouped(true);
			}
		}
	}, [elements, isGrouped]);

	const createGroup = () => {
		if (!isEditing) return;

		if (!groupId.current) {
			groupId.current = uuid();
			const elements = getElementsByProductId(id).map((data) => data.attributes);
			if (elements.length > 0) {
				stateDispatch({ type: "addGroup", id: groupId.current, elements: elements });
				Notify.success("Os elementos do produto foram agrupados.").bottomCenter();
			}
		} else {
			stateDispatch({ type: "ungroup", id: groupId.current });
			groupId.current = null;
		}
	}

	const handleMouseHover = (type: 'enter' | 'leave') => {
		const resetProductSelection = () => {
			stateDispatch({ type: "selectProduct", id: null });
			hoverTimeoutId.current = null;
		}

		if (type === 'enter') {
			stateDispatch({ type: "selectProduct", id });

			if (!hoverTimeoutId.current) {
				hoverTimeoutId.current = Number(setTimeout(resetProductSelection, 3000));
			}
		} else if (type === 'leave' && hoverTimeoutId.current) {
			clearTimeout(hoverTimeoutId.current);
			resetProductSelection();
		}
	}

	const GroupTitle: React.FC<{ title: string, clickable?: boolean }> = ({ title, clickable }) => {
		const className = `${centerClass}` +
			`${editor.section && groupId.current && 'cursor-pointer border-2 border-solid border-black'} rounded-lg`;

		return (
			<div className="w-2/5 py-2 px-5 bg-white rounded-br-full text-blue-500 font-semibold">
				<span className={className} onClick={clickable ? createGroup : () => null} >
					{title}
				</span>
			</div>
		)
	}

	const updateProductElements = async (productId: EntityID) => {
		if (!productId) return;

		const product = addedProducts.find(p => p.id === productId);
		if (!product) return;

		// Remove atual
		const reducedProducts = bannerProducts.current?.filter(p => p.id !== id) || [];
		const newBannerProducts: BannerProducts[] = reducedProducts.concat([{
			id: id,
			item: product
		}]);
		setBannerProducts(newBannerProducts);

		// Verifique se o produto já está mapeado e remova-o, se necessário
		for (const key in indexedProducts) {
			if (indexedProducts[key] === productId && key !== id) {
				removeProductFromIndex(productId);
			}
		}

		// Atualize o mapeamento
		setIndexedProducts({
			...indexedProducts,
			[id]: productId,
		});
		await updateProductTextElements(id, product);
		updateProductImageElements(id, product);
	}

	const customStyles: StylesConfig<SelectOption> = {
		control: (provided, state) => ({
			...provided,
			backgroundColor: 'rgb(147 197 253 / 0.5)',
			borderColor: 'white',
			borderWidth: '0px',
			color: 'white'
		}),
		menuList: provided => ({
			...provided,
			position: 'absolute',
			height: "125px",
			width: '100%',
		}),
		option: (provided, state) => ({
			...provided,
			backgroundColor: state.isSelected ? '#007bff' : 'white',
			color: state.isSelected ? 'white' : 'black',
			'&:hover': {
				backgroundColor: '#007bff',
				color: 'white',
			},
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: 'white',
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: 'white',
		}),
		indicatorSeparator: (provided, state) => ({
			...provided,
			backgroundColor: 'white',
		}),
		dropdownIndicator: (provided, state) => ({
			...provided,
			color: 'white',
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 })
	};

	const productElements = getElementsByProductId(id);

	return (
		<li className="relative w-full flex flex-row no-text-highlight" onMouseEnter={() => handleMouseHover('enter')} onMouseLeave={() => handleMouseHover('leave')}>
			<div className="flex flex-col items-center justify-center w-full min-h-20 mb-2 h-fit mt-4 bg-blue-500 border-2 border-blue-500 overflow-hidden rounded-lg hover:shadow-lg duration-300">
				<div className="w-full h-full flex flex-row items-center justify-between cursor-pointer" onClick={toggleRowOpened} >
					<GroupTitle title={`Produto ${displayPosition}`} />
					<div className="flex items-center pr-3">
						<div className="h-full p-2 hover:bg-blue-400 rounded-lg" onClick={(e) => { e.stopPropagation() }}>
							<svg className="mx-2 w-5 fill-white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m22 8c0-.478-.379-1-1-1h-13c-.62 0-1 .519-1 1v13c0 .621.52 1 1 1h13c.478 0 1-.379 1-1zm-13.5.5h12v12h-12zm-2.5-2.5h13.25c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-13.75c-.53 0-1 .47-1 1v13.75c0 .414.336.75.75.75s.75-.336.75-.75zm-2.5-2.5h13.75c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-14.25c-.53 0-1 .47-1 1v14.25c0 .414.336.75.75.75s.75-.336.75-.75z" fillRule="nonzero" /></svg>
						</div>

						<DeleteProductPopover productID={id} displayPosition={Number(displayPosition)} />
						{isRowOpened ? <ArrowUp /> : <ArrowDown />}
					</div>
				</div>
				{
					isRowOpened && (
						<div className={`w-[90%] py-4 px-2 flex flex-col text-white items-center justify-between ${isRowOpened ? 'h-full visible' : 'h-2 invisible'}`}>
							<div className=" w-full">
								<div className="flex items-center justify-between mb-2">
									<label className="block text-sm font-medium text-white">Selecione um Produto</label>
									<span className='text-center mb-2 cursor-default' >Elementos: <strong>{productElements.length}</strong></span>
								</div>
								<div className="w-full">
									{
										selectProducts.length > 0 ? (
											<Select<SelectOption>
												styles={customStyles}
												menuPortalTarget={document.body}
												menuPlacement="auto"
												noOptionsMessage={() => 'Nenhum produto adicionado'}
												options={selectProducts}
												placeholder="Escolha um produto"
												onChange={(option) => updateProductElements(option?.value as EntityID)}
												defaultValue={selectProducts.find((option) => option.value === selectedProductID)}
											/>
										) : (
											<button className="bg-blue-300/50 h-10 w-full px-2 text-center text-white border border-white cursor-pointer rounded-lg hover:bg-blue-300/40"
												onClick={showSearchModal}>
												Adicionar produtos
											</button>
										)
									}
								</div>
							</div>
							{
								isEditing &&
								(
									<div className="flex flex-col items-center mt-2 w-full">
										<ProductPanel productID={id} />
									</div>
								)
							}
						</div>
					)
				}
			</div>
		</li>
	);
}

export default ProductRow;

const ProductPanel: React.FC<ProductPanelInterface> = ({ productID }) => {
	const { stateDispatch } = useCanvasDispatch();

	const addElementType = (textType: 'description' | 'price' | 'price_per_gram') => {
		if (textType === 'description') {
			const text = TextHelper.getTextByType(textType);
			const element = ComponentProvider.text({ text, productID, textType, width: 350, height: 120, x: 25 });
			stateDispatch({ type: "addElement", attributes: element });
		}
		else if (textType === 'price') {
			const priceGroupID = uuid();
			let text = TextHelper.getTextByType('digits');
			let element = ComponentProvider.text({ text, productID, priceGroupID, textType: 'digits', y: 25, height: 100, width: 100, align: "center" });
			stateDispatch({ type: "addElement", attributes: element });

			text = TextHelper.getTextByType('cents');
			element = ComponentProvider.text({ text, productID, priceGroupID, textType: 'cents', x: element.x + 95, y: 25, width: 50, align: "left" });
			stateDispatch({ type: "addElement", attributes: element });

			text = 'R$';
			element = ComponentProvider.text({ text, productID, priceGroupID, textType: 'currency', x: element.x - 120, y: 25, width: 35, align: "right" });
			stateDispatch({ type: "addElement", attributes: element });
		}
	}

	const addImage = () => {
		const element = ComponentProvider.image({ productID, imageType: "product" });
		stateDispatch({ type: "addElement", attributes: element });
	}

	const addElementBarCode = () => {
		const barcodeElement = document.createElement('canvas');
		JsBarcode(barcodeElement, "000000000", {
			format: 'CODE128',
			background: "#00000000",
			height: 50,
			font: "HelveticaNeueBold"
		});
		const url = barcodeElement.toDataURL();
		const barCode = ComponentProvider.image({ text: "00000000", productID, image: url, imageType: "barCode", width: 100, height: 60 });
		stateDispatch({ type: "addElement", attributes: barCode });
	}

	const addElementCPFLimit = () => {
		const textType = "cpf_limit"
		const text = TextHelper.getTextByType(textType);
		const cpfLimit = ComponentProvider.text({ text, productID, textType, width: 300, height: 50, x: 25 });
		stateDispatch({ type: "addElement", attributes: cpfLimit });
	}

	const addElementPackType = () => {
		const textType = "product_source"
		const packType = ComponentProvider.text({ text: "UN", defaultText: "[[x]]", source: "packType", productID, textType, width: 100, height: 100, x: 25 });
		stateDispatch({ type: "addElement", attributes: packType });
	}

	const addElementPriceGram = () => {
		const priceGroupID = uuid();
		let text = "PREÇO EQUIVALENTE A [[X]] KG";

		let stripe = ComponentProvider.rectangle();
		stripe.y = 25;
		stripe.width = 150;
		stripe.height = 50;
		stripe.fill = "#f97316";
		stripe.cornerRadius = 10;
		stripe.productID = productID;
		stripe.priceGroupID = priceGroupID;
		stripe.textType = "price_per_gram";
		stripe.type = "rectangle";
		stateDispatch({ type: "addElement", attributes: stripe });

		let gramPrice = ComponentProvider.text({
			text,
			productID,
			fill: "#fff",
			fontFamily: "HelveticaNeueBold",
			priceGroupID,
			textType: 'price_per_gram',
			y: 25,
			height: 50,
			width: 150,
			align: "center"
		});
		stateDispatch({ type: "addElement", attributes: gramPrice });
	}

	const addElementLimitDate = () => {
		const textType = "date_limit"
		const text = TextHelper.getTextByType(textType);
		const dateLimit = ComponentProvider.text({ text, source: "endDate", productID, textType, width: 200, height: 50, x: 25 });
		stateDispatch({ type: "addElement", attributes: dateLimit });
	}

	const commonClasses = "flex flex-1 flex-col items-center justify-center bg-blue-300/50 text-center rounded-lg cursor-pointer py-2 hover:bg-blue-400/80 hover:scale-95 duration-300"

	return (
		<>
			<span className="tracking-wider">Adicionar</span>
			<div className='flex flex-wrap flex-row w-full items-center justify-center gap-2 overflow-auto pt-2' >
				<div className={`${commonClasses}`} onClick={() => addElementType('description')}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<DescriptionIcon />
					</span>
					<span>Descrição</span>
				</div>
				<div className={`${commonClasses}`} onClick={() => addElementType('price')}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white ">
						<MoneyIcon />
					</span>
					<span>Preço</span>
				</div>
				<div className={`${commonClasses}`} onClick={() => addImage()}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<FileImageIcon />
					</span>
					<span>Imagem</span>
				</div>

			</div>
			<div className='flex flex-wrap flex-row w-full items-center justify-center gap-2 overflow-auto pt-2' >
				<div className={`${commonClasses}`} onClick={addElementBarCode}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<BarCodeIcon />
					</span>
					<span> Código de Barras</span>
				</div>
				<div className={`${commonClasses}`} onClick={addElementCPFLimit}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<DigitalIcon />
					</span>
					<span>Limite por CPF</span>
				</div>

			</div >
			<div className='flex flex-wrap flex-row w-full items-center justify-center gap-2 overflow-auto pt-2' >
				<div className={`${commonClasses}`} onClick={addElementPackType}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<PackageIcon />
					</span>
					<span>Embalagem</span>
				</div>
				<div className={`${commonClasses}`} onClick={addElementPriceGram}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<WeightIcon />
					</span>
					<span>Grama</span>
				</div>
				<div className={`${commonClasses}`} onClick={addElementLimitDate}>
					<span className="flex items-center justify-center py-1 xl:p-3 rounded-full fill-white">
						<DateClockIcon />
					</span>
					<span>Data Limite</span>
				</div>
			</div>
		</>
	)
}
