import { ProductListItem } from "@/types/products";
import { BatchProduct, IBatchProductReducerAction } from "./interface/IBatchProductReducer";
import { addParamsToProduct } from "./helpers/HandleBatchProduct";
import { IProductParams } from "@/components/drawers/components/CardProductParams";

const BatchProductsReducer = (
    state: BatchProduct[],
    action: IBatchProductReducerAction
) => {
    const addProduct = (product: ProductListItem): BatchProduct[] => {
        return [...state, addParamsToProduct(product)]
    }

    const addProductList = (products: ProductListItem[]): BatchProduct[] => {
        return [...(products.map(prod => addParamsToProduct(prod)))];
    }

    const updateProductParams = (productParams: IProductParams): any[] => {
        const { dueDate, gram, packQuantity, packType, priceType } = productParams;

        return state.map(prod => {
            if (prod.ean !== productParams.productEan) return prod;

            return {
                ...prod,
                dueDate,
                wantUseProductPerGram: gram,
                limitationQuantity: packQuantity,
                limitation: packType,
                priceType
            }
        });
    }

    switch (action.type) {
        case "addProduct": return addProduct(action.product);
        case "addProductList": return addProductList(action.products);
        case "removeAllProducts": return [];
        case "updateProductParams": return updateProductParams(action.productParams);
    }
}

export default BatchProductsReducer;