import { ProductListItem } from "@/types/products"
import { BatchProduct } from "../interface/IBatchProductReducer"

const addParamsToProduct = (product: ProductListItem): BatchProduct => {
    return {
        ...product,
        limitation: (product.limitationQuantity ?? 0 )> 0 ? 'CPF' : null,
    }
}

export { addParamsToProduct }
